// src/components/Support.tsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../../../../config/firebase-config'; // Ensure Firebase is set up and db is imported
import { useAuthState } from 'react-firebase-hooks/auth';
import { useActionMessage } from '../../../common/actionMessage/ActionMessage';
import { useNavigate } from 'react-router-dom';
import theme, { brand } from '../../../../theme/theme';

const Support: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { showMessage } = useActionMessage();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [form, setForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const faqs = [
    {
      category: 'Listing',
      questions: [
        {
          question: 'How do I post my lease for sublease?',
          answer:
            'To post your lease, go to the home page and click "Make a Post" in the top navigation bar. Follow the steps to fill out your lease details, upload photos, set your price and availability, and agree to the terms and conditions. Once submitted, your listing will appear separately on the home page.',
        },
        {
          question:
            'How can I see bids on my listing and chat with interested people?',
          answer:
            'You can view bids/chats in two ways: (1) Click "See Details" on your listing, then go to the "Incoming Bids" tab. (2) Go to your "Inbox" in the top navigation bar and open the "Incoming Bids" tab. From there, you can message subletters, propose counteroffers, and plan key exchange or move-in details.',
        },
        {
          question: 'How do I accept, counteroffer, or reject a bid?',
          answer:
            'Open the chat for the bid you want to manage. Click "Review Offer" to accept the bid or propose a counteroffer. You’ll be taken to the contract signing page, where you can finalize the agreement and set up payment through Stripe. To reject an offer, click the "Reject" button to end the conversation.',
        },
      ],
    },
    {
      category: 'Bidding',
      questions: [
        {
          question: 'How do I bid on a listing?',
          answer:
            'Click "See Details" on a listing you’re interested in, then go to the "Place a Bid" tab. Adjust the dates and price or agree to them as listed, add a message, and submit your bid. Use the "Chat" tab to negotiate with the lister.',
        },
        {
          question: 'How do I view my bids and chat with the lister?',
          answer:
            'You can view bids/chats in two ways: (1) Click "See Details" on the listing you bid on, then go to the "Chat" tab. (2) Go to your "Inbox" in the top navigation bar and open the "Outgoing Bids" tab.',
        },
        {
          question: 'How do I accept an offer or withdraw my bid?',
          answer:
            'To accept an offer, click "Proceed to Payment" in the chat. You’ll finalize the contract and complete payment through Stripe. To withdraw your bid, click the "Withdraw" button to end the conversation.',
        },
      ],
    },
    {
      category: 'Payment and Cancellation',
      questions: [
        {
          question: 'How are payments handled on Dublease?',
          answer:
            'All payments are securely processed through Stripe. After the subletter makes a payment, the funds are held until the lease start date, at which point they are released to the sublessor.',
        },
        {
          question: 'What is the cancellation policy?',
          answer:
            'Cancellation policy development is in progress. For now, refunds for cancellations or contract terminations are managed on a case-by-case basis. To request a refund, please contact our support team via the contact form.',
        },
      ],
    },
    {
      category: 'Verification & Feedback',
      questions: [
        {
          question: 'How do I verify my account?',
          answer:
            'Account verification happens during profile creation. You’ll upload a profile picture and a picture of your student ID. Verified accounts receive a blue checkmark to boost trust and engagement. You can verify your account anytime by clicking "Profile" in the top navigation bar.',
        },
        {
          question: 'How can I share feedback or ask questions?',
          answer:
            'Share your thoughts or ask questions through the contact form below. We’re constantly improving Dublease and listening to customer feedback.',
        },
      ],
    },
  ];

  useEffect(() => {
    if (user) {
      setForm((prevForm) => ({
        ...prevForm,
        email: user.email || '',
      }));
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'contact-us'), {
        ...form,
        createdAt: new Date(), // Add createdAt field
      });
      showMessage(
        'Request Sent. Dublease Dubthanks you and will get back to you Dubquickly.',
        'info'
      );
      setForm({ name: '', email: '', phoneNumber: '', message: '' });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleGoBack = () => {
    navigate('/'); // Navigate back to homepage
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 15, mx: 'auto', maxWidth: '1200px', padding: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          sx={{
            fontSize: '20px',
          }}
        >
          Back
        </Button>
        {/* FAQs Section */}
        <Box
          sx={{
            width: isMobile ? '100%' : '100%',
            margin: '0 auto',
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{ textAlign: 'center', mb: 4, color: brand[500] }}
          >
            Frequently Asked Questions
          </Typography>
          {faqs.map((category, idx) => (
            <Box key={idx} sx={{ mb: 4, background: 'transparent' }}>
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: 'bold', color: brand[400] }}
              >
                {category.category}
              </Typography>
              {category.questions.map((faq, faqIdx) => (
                <Accordion
                  key={faqIdx}
                  sx={{
                    backgroundColor: 'transparent', // Transparent background for the accordion
                    boxShadow: 'none', // Remove any default shadow
                    border: '1px solid rgba(255, 255, 255, 0.2)', // Optional: Add a subtle border for clarity
                    borderRadius: '8px', // Optional: Rounded corners
                    '&:before': {
                      display: 'none', // Remove the default Material-UI focus outline
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${idx}-${faqIdx}-content`}
                    id={`panel${idx}-${faqIdx}-header`}
                  >
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: brand[400] }}>
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
        </Box>
        {/* Contact Us Section */}
        <Typography
          variant="h2"
          gutterBottom
          sx={{ textAlign: 'center', color: brand[500] }}
        >
          Contact Us
        </Typography>
        <Box sx={{ width: '100%', mx: 'auto', mb: 5 }}>
          {' '}
          {/* Centering the form */}
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleInputChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  name="name"
                  value={form.name}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <TextField
              label="Message"
              name="message"
              value={form.message}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              required
              sx={{ mb: 2 }}
            />
            <Button variant="contained" type="submit" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Support;

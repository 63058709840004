import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Skeleton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VerifiedUser, Lock } from '@mui/icons-material';
import LeaseDetailsTab from './tabs/LeaseDetailsTab';
import IncomingBids from '../bids/IncomingBids';
import Chat from '../chat/Chat';
import { Lease } from '../types/Lease';
import { auth } from '../../config/firebase-config';
import { getLease } from '../../api/leaseService';
import { useNavigate, useParams } from 'react-router-dom';
import { getBid } from '../../api/interactionService';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';

interface LeaseDetailsModalProps {
  defaultTabIndex?: number;
}

const LeaseDetailsModal = ({ defaultTabIndex = 0 }: LeaseDetailsModalProps) => {
  const { leaseId } = useParams();
  const [user] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const navigate = useNavigate();
  const [lease, setLease] = useState<Lease | undefined>(undefined);
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const [chatId, setChatId] = useState('');
  const [bidderId, setBidderId] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [bidData, setBidData] = useState<any | null>(null);
  const [bidExists, setBidExists] = useState(false);

  useEffect(() => {
    // Fetch Lease and then Fetch Bid Data
    const fetchLeaseAndBidData = async () => {
      setLoading(true);

      try {
        // Fetch Lease
        const leaseResponse = await getLease(leaseId || '');
        setLease(leaseResponse);
        // Fetch Bid Data if lease is successfully fetched
        if (leaseResponse && user && leaseResponse.owner_id !== user.uid) {
          const bidResponse = await getBid(
            leaseResponse.owner_id || '',
            user.uid || '',
            leaseId || ''
          );

          if (bidResponse) {
            setBidData(bidResponse);
            setChatId(bidResponse.chat_id || '');
            setBidderId(bidResponse.bidder_id || '');
            setOwnerId(bidResponse.owner_id || '');
            setBidExists(true);
          }
        }
      } catch (error) {
        console.error('Error fetching lease or bid data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaseAndBidData();
  }, [leaseId, user]);

  // Update tabIndex when modal opens based on the passed defaultTabIndex
  useEffect(() => {
    setTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  //Change Tabs
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  //Go back to previous page
  const onClose = () => navigate(-1);

  const goToChat = (
    chat_id?: string,
    owner_id?: string,
    bidder_id?: string
  ) => {
    console.log(chatId, ownerId, bidderId);
    if (chat_id && owner_id && bidder_id) {
      setChatId(chat_id);
      setOwnerId(owner_id);
      setBidderId(bidder_id);
      if (ownerId === user?.uid) setTabIndex(2);
      else setTabIndex(1);
    } else {
      setTabIndex(1);
    }
  };

  // TODO update
  if (!lease) {
    return <div> Page Doesn't Exist </div>;
  }

  const renderSkeletons = () => (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={400}
        sx={{ borderRadius: '10px', mb: 2 }}
      />
      <Skeleton
        animation="wave"
        variant="text"
        height={30}
        sx={{ mb: 1, width: '80%' }}
      />
      <Skeleton
        animation="wave"
        variant="text"
        height={20}
        sx={{ mb: 1, width: '50%' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={250}
        sx={{ borderRadius: '10px', mb: 2 }}
      />
    </>
  );

  // Determine the label for the second tab based on ownership
  const isOwner = lease?.owner_id === auth.currentUser?.uid;
  const incomingOrPlaceBids = isOwner
    ? 'Incoming Bids & Chat'
    : 'Place Bid & Chat';

  return (
    <Modal open onClose={onClose} aria-labelledby="lease-details-title">
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          maxHeight: '90vh', // Maximum height
          overflow: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: { xs: 2, sm: 4 },
          outline: 0, // Remove default focus outline
          ...(!user && {
            overflow: 'hidden',
          }),
        }}
      >
        {/* X Close Button */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {/* Address & Tabs */}
        {loading ? (
          renderSkeletons()
        ) : (
          <>
            <Typography
              id="lease-details-title"
              variant="h6"
              component="h2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: 24,
                color: 'primary.main',
                marginBottom: 2,
              }}
            >
              {lease.address}
              {lease.verified && (
                <Tooltip title="User is verified">
                  <VerifiedUser
                    sx={{
                      color: '#1976d2',
                      fontSize: '2rem',
                      verticalAlign: 'text-top',
                      ml: 1,
                    }}
                  />
                </Tooltip>
              )}
            </Typography>

            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Lease details tabs"
              sx={{ marginBottom: 2 }}
            >
              <Tab sx={{ fontSize: '14px' }} label="Lease Details" />
              {!isOwner && <Tab sx={{ fontSize: '14px' }} label="Chat" />}
              {/* <Tab sx={{ fontSize: '14px' }} label="User Details" /> */}
              {isOwner && (
                <Tab sx={{ fontSize: '14px' }} label={incomingOrPlaceBids} />
              )}
            </Tabs>

            {tabIndex === 0 && (
              <LeaseDetailsTab
                lease={lease}
                bidData={bidData}
                goToChat={goToChat}
                bidExists={bidExists}
                setBidExists={setBidExists}
              />
            )}
            {/* {tabIndex === 1 && <UserDetailsTab lease={lease} />} */}
            {tabIndex === 1 && (
              <Box>
                {isOwner ? (
                  // Content for "Check Bids" tab
                  <IncomingBids lease={lease} goToChat={goToChat} />
                ) : (
                  // Content for "Biddings" tab
                  // <PlaceBid lease={lease} goToChat={goToChat} />
                  <Chat
                    chatId={chatId}
                    ownerId={ownerId}
                    bidderId={bidderId}
                    leaseId={lease?.id || ''}
                  />
                )}
              </Box>
            )}
            {tabIndex === 2 && (
              <Chat
                chatId={chatId}
                ownerId={ownerId}
                bidderId={bidderId}
                leaseId={lease?.id || ''}
              />
            )}
          </>
        )}

        {!user && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slightly transparent white background
              backdropFilter: 'blur(5px)', // Blur effect
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              zIndex: 2, // Ensure it's on top of other elements
              height: '100%',
            }}
          >
            <Lock sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Please Sign In/Sign Up to view dublease
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                signInWithGoogle();
                navigate('/');
              }}
            >
              Sign In or Sign Up
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default LeaseDetailsModal;

import {
  WifiPassword,
  FitnessCenter as FitnessCenterIcon,
  Pool as PoolIcon,
  Weekend as WeekendIcon,
  Air as AirIcon,
  LocalLaundryService as LocalLaundryServiceIcon,
  Kitchen as KitchenIcon,
  Pets as PetsIcon,
  LocalParking as LocalParkingIcon,
} from '@mui/icons-material';

// export default LeaseDetailsTab;
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Lease } from '../../types/Lease';
import { brand } from '../../../theme/theme';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import GoogleMap from '../../utils/MapsComponent';
import UserDetailsTab from './UserDetailTab';
import PlaceBid from '../../bids/PlaceBid';
import { formatDate } from '../../utils/formatTimestamps';
import { checkProfileCompletion } from '../../../api/userService';
import { saveInitialInteraction } from '../../../api/interactionService';

interface LeaseDetailsTabProps {
  lease: Lease;
  bidData: any;
  bidExists: boolean;
  setBidExists: (exits: boolean) => void;
  goToChat: (chat_id?: string, owner_id?: string, bidder_id?: string) => void;
}

const LeaseDetailsTab = ({
  lease,
  bidData,
  bidExists,
  setBidExists,
  goToChat,
}: LeaseDetailsTabProps) => {
  const [user] = useAuthState(auth);
  const isOwner = user?.uid === lease.owner_id;
  const navigate = useNavigate();
  const { showMessage } = useActionMessage();
  const [chatId, setChatId] = useState('');
  const [bidderId, setBidderId] = useState('');
  const [ownerId, setOwnerId] = useState('');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const placeBidRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  // Handle Scrolling For Banner
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowBanner(!entry.isIntersecting);
      },
      { threshold: 1 }
    );

    if (placeBidRef.current) {
      observer.observe(placeBidRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const scrollToPlaceBid = () => {
    if (placeBidRef.current) {
      placeBidRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Delete the post and close the dialog
  const handleDeleteConfirm = async () => {
    if (lease && user && user?.uid === lease.owner_id) {
      // Get the Firebase ID token from the current user
      const idToken = await user.getIdToken();
      try {
        const response = await fetch(
          `http://localhost:5000/api/posts/delete/${lease.id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        const result = await response.json();
        showMessage(result.message, 'success');
        navigate('/');
      } catch (error) {
        console.error('Error deleting post:', error);
        showMessage('Error deleting post. Please try again.', 'error');
      }
    }
  };

  const submitBid = async (
    startDate: string,
    endDate: string,
    price: string,
    priceUnit: string,
    numBeds: number,
    additionalDetails: string
  ) => {
    setLoading(true);

    // Check if the user's profile is complete
    const isProfileComplete = await checkProfileCompletion();
    if (!isProfileComplete) {
      showMessage('You must create a profile to place a bid.', 'error');
      navigate('/Profile');
      setLoading(false);
      return;
    }

    try {
      const bidData = {
        owner_id: lease.owner_id || '',
        bidder_id: user?.uid || '',
        lease_id: lease.id || '',
        start_date: startDate,
        end_date: endDate,
        price: parseFloat(price),
        price_unit: priceUnit,
        num_beds_for_bid: numBeds,
        additional_details: additionalDetails,
      };

      const chat_id = await saveInitialInteraction(bidData);
      console.log('before if statement');
      if (chat_id) {
        setBidExists(true);
        setChatId(chat_id);
        setOwnerId(lease?.owner_id || '');
        setBidderId(user?.uid || '');
        goToChat(chat_id, lease.owner_id, user?.uid || '');
        showMessage('Bid submitted successfully!', 'success');
      } else {
        throw new Error('Failed to submit bid');
      }
    } catch (error: any) {
      showMessage(`Error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };
  // Amenities
  const amenitiesList = [
    { label: 'WiFi', icon: <WifiPassword /> },
    { label: 'Gym', icon: <FitnessCenterIcon /> },
    { label: 'Pool', icon: <PoolIcon /> },
    { label: 'Furnished', icon: <WeekendIcon /> },
    { label: 'AC', icon: <AirIcon /> },
    { label: 'Laundry', icon: <LocalLaundryServiceIcon /> },
    { label: 'Dishwasher', icon: <KitchenIcon /> },
    { label: 'Pet Friendly', icon: <PetsIcon /> },
    { label: 'Parking', icon: <LocalParkingIcon /> },
  ];

  const filteredAmenities = amenitiesList.filter((amenity) =>
    lease.amenities?.includes(amenity.label)
  );

  return (
    <>
      <Box>
        {/* Images */}
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '400px' }} // Adjust height as needed
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  src={image}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px 10px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>

        {/* Details */}
        <Box sx={{ mt: 2, p: 2, bgcolor: '#EAF0F5', borderRadius: '10px' }}>
          <Grid container spacing={2}>
            {/* Lease Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Lease Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Start Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.start_date)}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Price
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    ${lease.price} / {lease.price_unit}
                  </Typography>

                  {/* <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Total Property Bedrooms
                  </Typography> */}
                  {/* <Typography variant="body1" gutterBottom>
                    {lease.num_property_bedrooms}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Furnished
                  </Typography> */}
                  {/* <Typography variant="body1" gutterBottom>
                    {lease.furnished ? 'Yes' : 'No'}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Utilities Included
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.utilities ? 'Yes' : 'No'}
                  </Typography> */}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    End Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.end_date)}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Rooms for Lease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_rooms_for_lease} Bedroom(s) and{' '}
                    {lease.num_bathrooms} Bathroom(s)
                  </Typography>

                  {/* <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Bathrooms
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_bathrooms}
                  </Typography> */}
                  {/* 
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Parking Options
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.parking_option}
                  </Typography> */}
                  {/* 
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Pets Allowed
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.pets_allowed ? 'Yes' : 'No'}
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            {/* User Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                User Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <UserDetailsTab lease={lease} />
            </Grid>
            {/* Additional Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Additional Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {/* <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Roommates Aware
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.are_roommates_aware ? 'Yes' : 'No'}
                  </Typography> */}

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    House Gender
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.housing_gender}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Roommates Present During Sublease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_roommates_present_during_dublease}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: brand[500],
                  }}
                >
                  Amenities
                </Typography>
                <Grid container spacing={2}>
                  {filteredAmenities.length > 0 ? (
                    filteredAmenities.map((amenity, idx) => (
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        key={idx} /* Adjust to stack into 3 columns */
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // gap: 1,
                            p: 1, // Reduced padding for snug fit
                          }}
                        >
                          {amenity.icon} {amenity.label}
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 2,
                      }}
                    >
                      No Amenities Provided
                    </Typography>
                  )}
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: brand[500] }}
                  gutterBottom
                >
                  Description
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {lease.description
                    ? lease.description
                    : 'No Description Provided'}
                </Typography>
              </Grid>
            </Grid>
            {/* Location */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Location
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <GoogleMap address={lease.address} />
            </Grid>
          </Grid>
        </Box>

        {/* PlaceBid Section */}
        {!isOwner && (
          <Box ref={placeBidRef} sx={{ mt: 4 }}>
            <PlaceBid
              lease={lease}
              bidData={bidData}
              bidExists={bidExists}
              submitBid={submitBid}
            />
          </Box>
        )}

        {/* Delete & Edit Button */}
        {lease.owner_id === user?.uid && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '10px 0px 0px 0px',
              gap: '10px',
            }}
          >
            <Button
              variant="text"
              color="inherit"
              size="large"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() =>
                navigate('/ListYourPlace', { state: { post: lease } })
              }
            >
              Edit
            </Button>
          </Box>
        )}

        {/* Place A Bid Banner */}
        {showBanner && !bidExists && !isOwner && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Interested in this lease? Place your bid now!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={scrollToPlaceBid}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Go to Place Bid
            </Button>
          </Box>
        )}
        {/* Place A Bid Banner */}
        {showBanner && isOwner && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Check Your Incoming Bids
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToChat(ownerId)}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Go To Chats
            </Button>
          </Box>
        )}
        {/* Go To Chat */}
        {showBanner && bidExists && !isOwner && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Chat With The Host
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToChat(chatId, ownerId, bidderId)}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Go to Chat
            </Button>
          </Box>
        )}

        <ConfirmationDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete this post? Deleting the post will delete all bids, offers, and chats."
          confirmText="Delete"
          cancelText="Cancel"
        />
      </Box>
    </>
  );
};

export default LeaseDetailsTab;

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Paper,
  Skeleton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { auth } from '../../config/firebase-config';
import { getUserProfile } from '../../api/userService';
import { brand, gray } from '../../theme/theme';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import {
  deleteInteraction,
  getBid,
  getChatHistory,
  sendChatMessage,
} from '../../api/interactionService';
import io from 'socket.io-client';
import ReviewForm from './ReviewForm';
import ConfirmationDialog from '../common/confirmationDialog/ConfirmationDeleteDialog';
import { useNavigate } from 'react-router-dom';

const socket = io(process.env.REACT_APP_API_BASE_URL as string, {
  // withCredentials: true, // Ensure cookies are sent if needed
});

interface ChatProps {
  chatId: string;
  ownerId: string;
  bidderId: string;
  leaseId: string;
}

const Chat = ({ chatId, ownerId, bidderId, leaseId }: ChatProps) => {
  const [user] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherUserProfile, setOtherUserProfile] = useState<any>(null);
  const [currentOffer, setCurrentOffer] = useState<any>(null);
  const [isReviewing, setIsReviewing] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Function to scroll to the bottom of the chat
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  // Fetch & listen for chat data
  useEffect(() => {
    if (!user) {
      signInWithGoogle();
      return;
    }
    // load chat data
    const loadChatData = async () => {
      try {
        // Fetch the other user's profile data
        const profileIdToFetch = user?.uid === ownerId ? bidderId : ownerId;
        const profileData = await getUserProfile(profileIdToFetch);
        setOtherUserProfile(profileData);
        // Fetch the chat history
        const chatHistory = await getChatHistory(chatId);
        setMessages(chatHistory);

        // Fetch the current offer
        const offer = await getBid(ownerId, bidderId, leaseId);
        setCurrentOffer(offer);
      } catch (error) {
        console.error('Error loading chat data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadChatData();
    //load current bid

    // Join the chat room for real-time updates
    socket.emit('joinChat', chatId);

    // Listen for new messages
    socket.on('new_message', (newMsg) => {
      setMessages((prevMessages) => [...prevMessages, newMsg]);
      scrollToBottom();
    });

    return () => {
      socket.off('new_message');
    };
  }, [chatId, ownerId, bidderId, user, signInWithGoogle, leaseId]);

  // Scroll to the bottom of the chat when new messages are added
  useEffect(() => {
    if (messages || newMessage) {
      scrollToBottom();
    }
  }, [messages, newMessage]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      const user = auth.currentUser;
      if (user) {
        const messageData = {
          chat_id: chatId,
          sender_id: user.uid,
          message: newMessage,
          is_a_bid: false,
          is_info_msg: false,
          timestamp: new Date().toISOString(),
        };

        await sendChatMessage(messageData);
        setNewMessage(''); // Clear input
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handledeleteInteraction = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await deleteInteraction(chatId, ownerId, bidderId, leaseId);
        navigate(-1);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const callBackToChat = () => {
    scrollToBottom();
    setIsReviewing(false);
  };

  // Function for chat skeleton
  const renderChatSkeleton = () => (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '5px',
          backgroundColor: gray[100],
          borderBottom: '1px solid #ccc',
        }}
      >
        <Skeleton variant="circular" width={56} height={56} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          padding: '16px',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Skeleton
              variant="rectangular"
              width="75%"
              height={50}
              sx={{ mb: 2, borderRadius: '10px' }}
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          borderTop: '1px solid #ccc',
        }}
      >
        <Skeleton variant="rectangular" width="85%" height={50} />
        <Skeleton variant="rectangular" width={75} height={50} sx={{ ml: 2 }} />
      </Box>
    </>
  );

  return (
    <>
      {loading ? (
        renderChatSkeleton()
      ) : (
        <>
          {messages.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: { xs: '90vh', sm: '60vh' },
              }}
            >
              {/* Top Section: Other user's profile info */}
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px',
                  backgroundColor: gray[100],
                  borderBottom: '1px solid #ccc',
                }}
              >
                <Avatar
                  src={otherUserProfile?.profile_picture_url}
                  alt={otherUserProfile?.first_name}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography variant="h6">
                  {otherUserProfile?.first_name}
                </Typography>
                <Button
                  color="inherit"
                  onClick={() => setDeleteDialogOpen(true)}
                  sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '8px',
                    height: '40px',
                  }}
                >
                  Withdraw
                </Button>
              </Box>
              {isReviewing ? (
                <ReviewForm
                  chatId={chatId}
                  ownerId={ownerId}
                  bidderId={bidderId}
                  leaseId={leaseId}
                  currentOffer={currentOffer}
                  callBackToChat={callBackToChat}
                /> // Use the ReviewForm component
              ) : (
                <>
                  {/* Chat History */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      padding: '16px',
                      overflowY: 'scroll',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    {loading ? (
                      <Typography>Loading chat...</Typography>
                    ) : (
                      messages.map((msg, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent:
                              msg.is_a_bid || msg.is_info_msg
                                ? 'center'
                                : msg.sender_id === auth.currentUser?.uid
                                ? 'flex-end'
                                : 'flex-start',
                            width: '100%',
                          }}
                        >
                          {/* Conditional rendering for is_a_bid */}
                          {msg.is_a_bid || msg.is_info_msg ? (
                            <Typography
                              variant="body2"
                              sx={{
                                color: msg.is_info_msg ? gray[800] : brand[500],
                                fontStyle: 'italic',
                                textAlign: 'center',
                                padding: '2px 0',
                              }}
                            >
                              {msg.message}
                            </Typography>
                          ) : (
                            <Paper
                              sx={{
                                padding: '8px 8px 1px 8px',
                                alignSelf:
                                  msg.sender_id === auth.currentUser?.uid
                                    ? 'flex-end'
                                    : 'flex-start',
                                backgroundColor:
                                  msg.sender_id === auth.currentUser?.uid
                                    ? brand[300]
                                    : gray[300],
                                maxWidth: '75%',
                              }}
                            >
                              <Typography variant="body2" gutterBottom>
                                {msg.message}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {new Date(msg.timestamp).toLocaleTimeString()}
                              </Typography>
                            </Paper>
                          )}
                        </Box>
                      ))
                    )}
                    <div ref={chatEndRef}></div>
                  </Box>

                  {/* Bottom Section: Input bar and buttons */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '16px',
                      borderTop: '1px solid #ccc',
                      paddingBottom: '16px',
                    }}
                  >
                    <TextField
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      variant="outlined"
                      fullWidth
                      placeholder="Type your message..."
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            color="primary"
                            onClick={handleSendMessage}
                            disabled={!newMessage.trim()}
                            sx={{
                              '&:hover': {
                                transform: 'scale(1.2)',
                              },
                              transition: 'transform 0.2s ease-in-out',
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        marginRight: '16px',
                        height: '50px',
                        '.MuiOutlinedInput-root': {
                          paddingRight: 0,
                        },
                      }}
                    />
                    {user?.uid === ownerId ? (
                      <Button
                        variant="contained"
                        color="inherit"
                        sx={{
                          marginLeft: '16px',
                          height: '50px',
                        }}
                        onClick={() => setIsReviewing(true)}
                      >
                        Review Offer
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={currentOffer?.is_live === false}
                        sx={{
                          marginLeft: '16px',
                          height: '50px',
                        }}
                      >
                        Accept Offer
                      </Button>
                    )}
                  </Box>
                </>
              )}

              <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handledeleteInteraction}
                title="Are you sure you want to withdraw? All chats, biddings, and live offers will be deleted."
                confirmText="Delete"
                cancelText="Cancel"
              />
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              You must place a bid to chat with the host
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default Chat;

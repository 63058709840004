import React, { ChangeEvent } from 'react';
import { Grid, Button, Typography, Avatar, Skeleton, Box } from '@mui/material';
import { PhotoCamera, AssignmentInd, VerifiedUser } from '@mui/icons-material';

interface AccountVerificationProps {
  handleProfilePictureUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleStudentIdUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  profilePictureLoading: boolean;
  studentIdPictureLoading: boolean;
  profilePicture: string | null; // URL of the uploaded profile picture
  studentIdPicture: string | null; // URL of the uploaded student ID picture
}

const AccountVerification: React.FC<AccountVerificationProps> = ({
  handleProfilePictureUpload,
  handleStudentIdUpload,
  profilePicture,
  studentIdPicture,
  profilePictureLoading,
  studentIdPictureLoading,
}) => (
  // <Grid
  //   container
  //   spacing={2}
  //   mt={3}
  //   mb={3}
  //   sx={{
  //     backgroundColor: '#f5f5f5',
  //     padding: '10px',
  //     borderRadius: '10px',
  //     border: '1px solid #ccc',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //   }}
  // >
  <Box
    sx={{
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // justifyContent: 'center',
      padding: 3, // Uniform padding around the box
      backgroundColor: '#f5f5f5',
      borderRadius: '10px',
      border: '1px solid #ccc',
      width: '100%', // Take full width of the parent container
    }}
  >
    <Grid item xs={12}>
      <Typography variant="h6" sx={{ color: '#1976d2' }} gutterBottom>
        Verify Your Account
        <VerifiedUser sx={{ color: '#1976d2', ml: 1 }} />
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        sx={{ mb: 2, fontStyle: 'italic' }}
      >
        Upload a profile picture and a photo of your student ID to verify your
        account and earn a blue checkmark
      </Typography>
    </Grid>
    <Grid
      container
      spacing={4} // Ensure consistent spacing between grid items
      justifyContent="center" // Center the grid horizontally
      alignItems="center" // Center the grid vertically
    >
      {/* Profile Picture Upload */}
      <Grid
        item
        xs={12}
        sm={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {profilePictureLoading ? (
          <Skeleton
            variant="circular"
            width={128}
            height={128}
            sx={{ mb: 1 }}
          />
        ) : profilePicture ? (
          <Avatar
            key={profilePicture}
            src={profilePicture}
            alt="Profile Picture"
            sx={{ width: 128, height: 128, mb: 1 }}
          />
        ) : (
          <PhotoCamera sx={{ fontSize: 100, mb: 4, color: 'grey' }} />
        )}
        <Button
          variant="outlined"
          component="label"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Upload Profile Picture
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleProfilePictureUpload}
          />
        </Button>
      </Grid>

      {/* Student ID Upload */}
      <Grid
        item
        xs={12}
        sm={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {studentIdPictureLoading ? (
          <Skeleton
            variant="circular"
            width={128}
            height={128}
            sx={{ mb: 1 }}
          />
        ) : studentIdPicture ? (
          <Avatar
            src={studentIdPicture}
            alt="Student ID"
            sx={{ width: 128, height: 128, mb: 1 }}
          />
        ) : (
          <AssignmentInd sx={{ fontSize: 100, mb: 4, color: 'grey' }} />
        )}
        <Button
          variant="outlined"
          component="label"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Upload Student ID Picture
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleStudentIdUpload}
          />
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default AccountVerification;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  Typography,
  InputAdornment,
  Divider,
} from '@mui/material';
import { formatToDateInput } from '../utils/formatTimestamps';
import { auth } from '../../config/firebase-config';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { Lease } from '../types/Lease';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import { checkProfileCompletion } from '../../api/userService';
import { getBid, saveInitialInteraction } from '../../api/interactionService';
import Chat from '../chat/Chat';
import { AttachMoney } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { brand } from '../../theme/theme';

interface PlaceBidTabProps {
  lease: Lease;
  bidData: any | null;
  bidExists: boolean;
  submitBid: (
    startDate: string,
    endDate: string,
    price: string,
    priceUnit: string,
    numBeds: number,
    additionalDetails: string
  ) => void;
}

const PlaceBidTab = ({
  lease,
  bidData,
  bidExists,
  submitBid,
}: PlaceBidTabProps) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [startDate, setStartDate] = useState(
    formatToDateInput(lease.start_date)
  );
  const [endDate, setEndDate] = useState(formatToDateInput(lease.end_date));
  const [price, setPrice] = useState(lease.price);
  const [priceUnit, setPriceUnit] = useState(lease.price_unit);
  const [numBeds, setNumBeds] = useState(lease.num_rooms_for_lease);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const { showMessage } = useActionMessage();

  useEffect(() => {
    if (bidData) {
      setStartDate(formatToDateInput(bidData.start_date));
      setEndDate(formatToDateInput(bidData.end_date));
      setPrice(bidData.price);
      setPriceUnit(bidData.price_unit);
      setNumBeds(bidData.num_rooms_for_lease);
    } else {
      setStartDate(formatToDateInput(lease.start_date));
      setEndDate(formatToDateInput(lease.end_date));
      setPrice(lease.price);
      setPriceUnit(lease.price_unit);
      setNumBeds(lease.num_rooms_for_lease);
    }
  }, [bidData, lease]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitBid(startDate, endDate, price, priceUnit, numBeds, additionalDetails);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 2,
      }}
    >
      {/* Conditionally render chat or place bid form */}
      <Typography variant="h6" sx={{ color: brand[500] }}>
        Place A Bid
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              required
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={loading || bidExists}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              required
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={loading || bidExists}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <TextField
                  label="Price"
                  type="number"
                  required
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  disabled={loading || bidExists}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Unit Price"
                  required
                  select
                  value={priceUnit || ''}
                  onChange={(e) => setPriceUnit(e.target.value)}
                  fullWidth
                  disabled={loading || bidExists}
                >
                  <MenuItem value="week">Per week</MenuItem>
                  <MenuItem value="month">Per month</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Number of Beds"
              type="number"
              required
              value={numBeds || ''}
              onChange={(e) => setNumBeds(Number(e.target.value))}
              fullWidth
              disabled={loading || bidExists}
            />
          </Grid>
        </Grid>

        <TextField
          label="Additional Details"
          multiline
          rows={4}
          value={additionalDetails}
          onChange={(e) => setAdditionalDetails(e.target.value)}
          fullWidth
          disabled={loading || bidExists}
          placeholder="Provide any additional details about your preferred timeline, price, and any other details to kick off the conversation with this host"
        />

        <Button
          type="submit"
          variant="contained"
          color={!bidExists ? 'primary' : 'secondary'}
          fullWidth
          disabled={loading || bidExists}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit Bid'}
        </Button>
      </Box>
    </Box>
  );
};

export default PlaceBidTab;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Box, useMediaQuery } from '@mui/material';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase-config'; // Ensure you import your Firebase config
import theme from '../../theme/theme';

const AuthControl: React.FC = () => {
  const [user] = useAuthState(auth);
  const [signInWithGoogle, _, loading, error] = useSignInWithGoogle(auth);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSignOut = async () => {
    await signOut(auth);
  };
  //TODO: only add umich email addresses
  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress size={30} />
      </Box>
    );
  }
  //TODO: Add an error message
  if (error) {
    return <p>Error: Please Refresh & Try Again</p>;
  }

  return (
    <>
      {user ? (
        <Button
          onClick={handleSignOut}
          variant={isSmallScreen ? 'text' : 'contained'} // Change variant based on screen size
          color={isSmallScreen ? 'inherit' : 'primary'} // Change color based on screen size
          sx={{ fontSize: '15px' }}
        >
          Log Out
        </Button>
      ) : (
        <Button onClick={() => signInWithGoogle()} variant="contained">
          Sign In | Sign Up
        </Button>
      )}
    </>
  );
};

export default AuthControl;

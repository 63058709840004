// import { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Button from '@mui/material/Button';
// import Container from '@mui/material/Container';
// import Drawer from '@mui/material/Drawer';
// import MenuIcon from '@mui/icons-material/Menu';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import { MenuItem } from '@mui/material';
// import { Divider } from '@mui/material';
// import AuthControl from '../../auth/AuthControl';
// import '../../../theme/global.css';
// import { brand } from '../../../theme/theme';
// import InboxModal from '../../inbox/Inbox';

// function Navbar() {
//   const navigate = useNavigate();
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [inboxOpen, setInboxOpen] = useState(false);

//   return (
//     <>
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'flex-start',
//           flexDirection: 'column',
//           position: 'fixed',
//           top: 50, // Adjust to your design needs
//           left: 40, // Adjust to your design needs
//           gap: 0.5,
//           '&:hover': {
//             opacity: 0.8,
//           },
//         }}
//         onClick={() => navigate('/')}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             gap: 1,
//           }}
//         >
//           <Box
//             component="img"
//             src="/Dublease_Logo.png"
//             alt="Dublease Logo"
//             sx={{
//               width: { xs: '40px', md: '80px' }, // Adjust width for responsiveness
//               height: 'auto',
//             }}
//           />
//           <Typography
//             variant="h2"
//             sx={{
//               color: brand[500],
//               fontWeight: 'bold',
//             }}
//           >
//             dublease
//           </Typography>
//         </Box>
//         {/* Subtitle */}
//         <Typography
//           variant="subtitle1"
//           sx={{
//             textAlign: 'center',
//             width: '100%',
//             fontSize: '20px',
//             color: 'gray', // Adjust color to match design
//             fontStyle: 'italic',
//           }}
//         >
//           subleasing for college students
//         </Typography>
//       </Box>
//       <AppBar
//         position="fixed"
//         color="default"
//         sx={{
//           borderRadius: '999px',
//           border: '1px solid',
//           borderColor: 'divider',
//           top: 16,
//           left: '50%',
//           transform: 'translateX(-50%)',
//           backgroundColor: 'rgba(255, 255, 255, 0.4)',
//           backdropFilter: 'blur(24px)',
//           boxShadow: 5,
//           width: { xs: '90%', md: '45%' },
//           marginTop: 5,
//         }}
//       >
//         <Container>
//           <Toolbar
//             sx={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               flexDirection: 'row',
//               marginLeft: { xs: 0 },
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 alignItems: 'center',

//                 cursor: 'pointer',
//                 '&:hover': {
//                   opacity: 0.8,
//                 },
//               }}
//               onClick={() => navigate('/')}
//             >
//               <Box
//                 component="img"
//                 src="/Dublease_Logo.png"
//                 alt="Dublease Logo"
//                 sx={{
//                   width: { xs: '50px', md: '50px' }, // Adjust width based on screen size
//                   height: 'auto',
//                   display: { xs: 'none', sm: 'block' }, // Hide on very small screens
//                 }}
//               />

//               {/* Branding with subtitle */}
//               <Box>
//                 <Typography
//                   variant="h5"
//                   sx={{ color: brand[500], ml: 1 }} //mb: 0.5,
//                 >
//                   dublease
//                 </Typography>
//                 <Typography variant="h6" color="inherit" sx={{ ml: 1 }}>
//                   subleasing for college students
//                 </Typography>
//               </Box>
//             </Box>

//             {/* Menu items for desktop */}
//             <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
//               <Button
//                 onClick={() => navigate('/ListYourPlace')}
//                 className="custom-gray-button"
//                 sx={{ fontSize: '15px' }}
//               >
//                 List Your Place
//               </Button>
//               <Button
//                 onClick={() => navigate('/Profile')}
//                 className="custom-gray-button"
//                 sx={{ fontSize: '15px' }}
//               >
//                 Profile
//               </Button>
//               <Button
//                 onClick={() => setInboxOpen(true)}
//                 className="custom-gray-button"
//                 sx={{ fontSize: '15px' }}
//               >
//                 Inbox
//               </Button>
//               <Button
//                 component={Link}
//                 to="/Support"
//                 className="custom-gray-button"
//                 sx={{ fontSize: '15px' }}
//               >
//                 Support
//               </Button>
//               <Divider orientation="vertical" flexItem sx={{ m: 1 }} />
//               <AuthControl />
//             </Box>

//             {/* Mobile View */}
//             <Box sx={{ display: { xs: 'block', md: 'none' } }}>
//               <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
//                 <MenuIcon />
//               </IconButton>
//               <Drawer
//                 anchor="right"
//                 open={drawerOpen}
//                 onClose={() => setDrawerOpen(false)}
//               >
//                 <Box sx={{ width: 250 }}>
//                   <MenuItem
//                     onClick={() => navigate('/ListYourPlace')}
//                     className="custom-gray-button"
//                     sx={{ fontSize: '15px' }}
//                   >
//                     List Your Place
//                   </MenuItem>
//                   <MenuItem
//                     onClick={() => navigate('/Profile')}
//                     className="custom-gray-button"
//                     sx={{ fontSize: '15px' }}
//                   >
//                     Profile
//                   </MenuItem>
//                   <MenuItem
//                     onClick={() => setInboxOpen(true)}
//                     className="custom-gray-button"
//                     sx={{ fontSize: '15px' }}
//                   >
//                     Inbox
//                   </MenuItem>
//                   <MenuItem
//                     component={Link}
//                     to="/AboutUs"
//                     onClick={() => setDrawerOpen(false)}
//                     className="custom-gray-button"
//                     sx={{ fontSize: '15px' }}
//                   >
//                     About Us
//                   </MenuItem>
//                   <MenuItem
//                     to="/Support"
//                     component={Link}
//                     onClick={() => setDrawerOpen(false)}
//                     className="custom-gray-button"
//                     sx={{ fontSize: '15px' }}
//                   >
//                     Support
//                   </MenuItem>
//                   <MenuItem onClick={() => navigate('/Terms&Conditions')}>
//                     Terms & Conditions
//                   </MenuItem>
//                   <AuthControl />
//                 </Box>
//               </Drawer>
//             </Box>
//           </Toolbar>
//         </Container>
//       </AppBar>
//       {/* <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flexDirection: 'column',
//           textAlign: 'center',
//           mt: { xs: 15, sm: 15 }, // Margin below navbar
//         }}
//       >
//         <Typography
//           color="inherit"
//           sx={{
//             fontStyle: 'italic',
//             fontWeight: 'bold',
//             fontColor: 'black',
//             fontSize: { xs: '1.5rem', sm: '2.5rem', md: '2.5rem' },
//           }}
//         >
//           Subleasing for College Students
//         </Typography>
//       </Box> */}
//       <InboxModal open={inboxOpen} handleClose={() => setInboxOpen(false)} />
//     </>
//   );
// }

// export default Navbar;
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';
import AuthControl from '../../auth/AuthControl';
import '../../../theme/global.css';
import { brand } from '../../../theme/theme';
import InboxModal from '../../inbox/Inbox';

function Navbar() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          borderRadius: '999px',
          border: '1px solid',
          borderColor: 'divider',
          top: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          backdropFilter: 'blur(24px)',
          boxShadow: 5,
          width: { xs: '90%', sm: '80%', md: '80%' },
          marginTop: 2,
        }}
      >
        <Container>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              marginLeft: { xs: 0, md: -2 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',

                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => navigate('/')}
            >
              <Box
                component="img"
                src="/Dublease_Logo.png"
                alt="Dublease Logo"
                sx={{
                  width: { xs: '50px', md: '70px' }, // Adjust width based on screen size
                  height: 'auto',
                  display: { xs: 'none', sm: 'block' }, // Hide on very small screens
                  mr: 2,
                }}
              />

              {/* Branding with subtitle */}
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: brand[500] }} //mb: 0.5,
                >
                  dublease
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    mt: -1,
                    textAlign: 'left',
                    width: '100%',
                    fontSize: '17px',
                    color: 'grey', // Adjust color to match design
                    fontStyle: 'italic',
                  }}
                >
                  subleasing for college students
                </Typography>
              </Box>
            </Box>

            {/* Menu items for desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
              <Button
                onClick={() => navigate('/ListYourPlace')}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                List Your Place
              </Button>
              <Button
                onClick={() => navigate('/Profile')}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Profile
              </Button>
              <Button
                onClick={() => setInboxOpen(true)}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Inbox
              </Button>
              <Button
                component={Link}
                to="/Support"
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Support
              </Button>
              <Divider orientation="vertical" flexItem />
              <AuthControl />
            </Box>

            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              >
                <Box sx={{ width: 250 }}>
                  <MenuItem
                    onClick={() => navigate('/ListYourPlace')}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    List Your Place
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate('/Profile')}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => setInboxOpen(true)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Inbox
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/AboutUs"
                    onClick={() => setDrawerOpen(false)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    About Us
                  </MenuItem>
                  <MenuItem
                    to="/Support"
                    component={Link}
                    onClick={() => setDrawerOpen(false)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Support
                  </MenuItem>
                  <MenuItem onClick={() => navigate('/Terms&Conditions')}>
                    Terms & Conditions
                  </MenuItem>
                  <AuthControl />
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <InboxModal open={inboxOpen} handleClose={() => setInboxOpen(false)} />
    </>
  );
}

export default Navbar;

import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { Outlet, useNavigate } from 'react-router-dom';
import { getNotifications } from '../../api/notificationService';
import BidCard from '../bids/BidCard';

interface InboxModalProps {
  open: boolean;
  handleClose: () => void;
}

const InboxModal = ({ open, handleClose }: InboxModalProps) => {
  const [user] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [tabIndex, setTabIndex] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const goToLease = (lease_id: string) => {
    // navigate(-1);
    if (!lease_id) return;
    handleClose();
    navigate(`/posts/${lease_id}`);
  };

  useEffect(() => {
    if (!user) {
      signInWithGoogle();
      return;
    }

    const fetchNotifications = async () => {
      try {
        setLoading(true);
        const data = await getNotifications();
        setNotifications(data);
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
    // Fetch data for notifications
  }, [open, signInWithGoogle, user]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="lease-details-title"
      >
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'divider',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
            maxHeight: '90vh', // Maximum height
            overflowY: 'auto', // Allow vertical scrolling
            bgcolor: 'background.paper',
            boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
            p: 4,
            outline: 0, // Remove default focus outline
          }}
        >
          {/* X Close Button */}
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: 24,
              color: 'primary.main',
              marginBottom: 2,
            }}
          >
            Inbox
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Inbox tabs"
            sx={{ marginBottom: 2 }}
          >
            <Tab sx={{ fontSize: '14px' }} label="Notifications" />
          </Tabs>
          {loading ? (
            // Show Skeleton Loader when loading
            <Box
              sx={{
                maxHeight: '60vh', // Limit the height of the skeleton section
                overflowY: 'auto', // Enable scrolling for the skeleton
              }}
            >
              {[...Array(5)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    mb: 1,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  {/* Skeleton for Avatar */}
                  <Skeleton
                    variant="circular"
                    width={56}
                    height={56}
                    sx={{ mr: 2 }}
                  />

                  {/* Skeleton for Bid Info */}
                  <Box sx={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} />
                    <Skeleton
                      variant="text"
                      width="80%"
                      height={20}
                      sx={{ mt: 1 }}
                    />
                    <Skeleton
                      variant="text"
                      width="30%"
                      height={20}
                      sx={{ mt: 1 }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            // Show content when not loading
            tabIndex === 0 && (
              <Box
                sx={{
                  maxHeight: '60vh', // Limit the height of the cards section
                  overflowY: 'auto', // Enable scrolling for the cards
                }}
              >
                {notifications.length > 0 ? (
                  notifications.map((data, index) => (
                    <BidCard
                      key={data?.bid_id || index}
                      bidderName={data?.user_first_name || 'dublease'}
                      bidderProfilePic={
                        data?.user_profile_picture_url
                          ? data.user_profile_picture_url
                          : data.interaction_type === 'notification'
                          ? '/Dublease_Logo.png'
                          : ''
                      }
                      seen={data.message_seen}
                      lastMessage={data?.message || ''}
                      label={data?.interaction_type || 'notification'}
                      timestamp={data?.message_timestamp || ''}
                      onClick={() => goToLease(data?.lease_id || '')} // Use the passed function to navigate to chat
                    />
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No notifications found.
                  </Typography>
                )}
              </Box>
            )
          )}
        </Box>
      </Modal>
      <Outlet />
    </>
  );
};

export default InboxModal;

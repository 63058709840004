import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { brand } from '../../../../theme/theme'; // Replace with your theme file if needed
import { Link, useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <Modal open onClose={() => navigate(-1)}>
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' },
          height: '90vh',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`,
          p: 4,
          outline: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', color: brand[500] }}
          >
            Dublease Privacy Policy
          </Typography>
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            maxHeight: '90vh',
            overflowY: 'auto',
            pr: 2,
          }}
        >
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Effective Date: {new Date().getFullYear()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Dublease (“we,” “our,” or “us”) values your privacy and is committed
            to protecting your personal information. This Privacy Policy
            explains how we collect, use, and safeguard your information when
            you access or use the Dublease website and services (collectively,
            the “Service”). By using the Service, you consent to the practices
            described in this Privacy Policy.
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            We may collect the following types of information:
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Account Information: Name, email address, phone number, and profile
            details provided during account creation.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Sublease Information: Property details, lease terms, and other data
            you provide for creating or responding to listings.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Payment Information: Financial details necessary for processing
            transactions, such as payment method and billing address.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Usage Data: Information about how you interact with the Service,
            such as pages viewed, features used, and activity logs.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            Location Data: Approximate location data based on IP address or
            other technologies.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            SMS Campaign Information: Mobile phone number and communication
            preferences related to our SMS campaign.
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            We use the information we collect for the following purposes:
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To provide, maintain, and improve the Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To process transactions and facilitate connections between users.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To send notifications, updates, and reminders via email or SMS.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To ensure compliance with our Terms and Conditions.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To personalize the user experience and enhance customer support.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            To analyze Service performance and user behavior.
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
            3. SMS Campaign Privacy Notice
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            For users who opt-in to Dublease Notifications via SMS:
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            We collect and use your mobile number exclusively to send updates
            related to your account activity, such as listing updates, bid
            notifications, and important reminders.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            We do not sell, rent, or share your mobile information with third
            parties for promotional or marketing purposes.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            You may opt out of receiving SMS messages at any time by replying
            “STOP” to any message or contacting us directly.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1, ml: 3 }}>
            For more information, visit our{' '}
            <Link
              to="/Terms&Conditions"
              style={{ color: brand[500], textDecoration: 'none' }}
            >
              Terms and Conditions
            </Link>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicy;

import { Loader } from '@googlemaps/js-api-loader';

let googleMapsInstance: typeof google | null = null;

export const loadGoogleMapsApi = async (): Promise<typeof google> => {
  if (googleMapsInstance) {
    return googleMapsInstance; // Return existing instance if already loaded
  }
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
  const loader = new Loader({
    apiKey,
    version: 'weekly',
    libraries: ['places'], // Ensure "places" library is included
  });

  googleMapsInstance = await loader.load();

  // Assign `google` to the global `window` object
  (window as any).google = googleMapsInstance;
  return googleMapsInstance;
};

import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { loadGoogleMapsApi } from './LoadGoogleMapsAPI';

interface GoogleMapProps {
  address: string;
  height?: string; // Optional: specify map height
}

const GoogleMap: React.FC<GoogleMapProps> = ({ address, height = '400px' }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadMap = async () => {
      try {
        const google = await loadGoogleMapsApi();
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK' && results && results[0]) {
            const location = results[0].geometry.location;

            // Initialize the map
            const map = new google.maps.Map(mapContainerRef.current!, {
              center: location,
              zoom: 15,
              disableDefaultUI: true, //  cleaner look
            });
            // Add a Circle for anonymity
            new google.maps.Circle({
              map,
              center: location,
              radius: 100, // 1000 feet in meters
              fillColor: '#61dafb',
              fillOpacity: 0.3,
              strokeColor: '#0056b3',
              strokeOpacity: 0.6,
              strokeWeight: 2,
            });
          } else {
            console.error('Geocode failed:', status);
          }
        });
      } catch (error) {
        console.error('Error loading Google Maps:', error);
      }
    };

    loadMap();
  }, [address]);

  return (
    <Box
      ref={mapContainerRef}
      sx={{
        width: '100%',
        height,
      }}
    />
  );
};

export default GoogleMap;

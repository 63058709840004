// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import theme, { brand } from './theme/theme';

import Navbar from './components/layout/navbar/Navbar';
import Footer from './components/layout/footer/Footer';
import AboutUs from './components/layout/footer/sections/AboutUs';
import Support from './components/layout/footer/sections/Support';
import LeaseDisplay from './components/dubleases/LeaseDisplay';
import { ActionMessageProvider } from './components/common/actionMessage/ActionMessage';
import { loadGoogleMapsApi } from './components/utils/LoadGoogleMapsAPI';
import LeaseDetailsModal from './components/dubleases/LeaseDetailsModal';
import PostForm from './components/dubleases/form/PostForm';
import Profile from './components/profile/Profile';
import TermsAndConditions from './components/layout/footer/sections/TermsAndConditions';
import PrivacyPolicy from './components/layout/footer/sections/PrivacyPolicy';

const App: React.FC = () => {
  const [splashVisible, setSplashVisible] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // TODO pre-fetch data (cache) for the LeaseDisplay component
  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3000); // 2 seconds delay

    // Load Google Maps API
    loadGoogleMapsApi();

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ActionMessageProvider>
        {splashVisible ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{
                duration: 1,
                delay: 0.5,
                ease: 'easeInOut',
              }}
              style={{ textAlign: 'center' }}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    p: 2,
                  }}
                >
                  <Box
                    component="img"
                    src="/Dublease_Logo.png"
                    alt="Dublease Logo"
                    sx={{
                      width: { xs: '100px', md: '200px' }, // Adjust width for responsiveness
                      //width: '200px',
                      height: 'auto',
                    }}
                  />
                  <Typography
                    variant={isSmallScreen ? 'h2' : 'h1'}
                    sx={{ fontWeight: 'bold', mb: 2, color: brand[500] }}
                    component={motion.h1}
                  >
                    dublease
                  </Typography>
                </Box>
                {/* Subtitle */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: 'center',
                    // width: '100%',
                    fontSize: '30px',
                    color: 'gray', // Adjust color to match design
                    fontStyle: 'italic',
                  }}
                >
                  subleasing for college students
                </Typography>
              </Box>
            </motion.div>
          </Box>
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={
                  <Box sx={{ p: 5, pt: 15 }}>
                    <LeaseDisplay />
                  </Box>
                }
              >
                <Route path="/Profile" element={<Profile />} />
                <Route path="/ListYourPlace" element={<PostForm />} />
                <Route path="posts/:leaseId" element={<LeaseDetailsModal />} />
                <Route
                  path="/Terms&Conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              </Route>
              <Route path="/Support" element={<Support />} />
              <Route path="/AboutUs" element={<AboutUs />} />
            </Routes>
            <Footer />
          </>
        )}
      </ActionMessageProvider>
    </Router>
  );
};

export default App;

import { Button, CircularProgress, Grid } from '@mui/material';

// Define the props for the SubmitButton component
interface SubmitButtonProps {
  isSubmitting: boolean;
  onReset: () => void;
  onDelete?: () => void;
  post?: any;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onReset,
  onDelete,
  isSubmitting,
  post,
}) => {
  return (
    <Grid container spacing={2} mt={3}>
      {post ? (
        // If post exists (user is owner), show "Delete" button
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onDelete}
            fullWidth
          >
            Delete Post
          </Button>
        </Grid>
      ) : (
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={onReset}
            fullWidth
          >
            Clear All
          </Button>
        </Grid>
      )}
      <Grid item xs={6}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}{' '}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmitButton;

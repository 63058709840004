import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Tooltip,
  Link,
  Grid,
  Typography,
} from '@mui/material';

interface TermsOfServiceProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({
  checked,
  onChange,
}) => {
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name="terms_of_service"
          />
        }
        label={
          <Tooltip
            title="By Checking The Box, You Understand That Subleasing is Strictly
            Prohibited Should Your Lease Agreement Not Allow It, And That
            You Are Liable For Any Legal Consequences That May Arise From
            Subleasing Without Permission."
            placement="left"
            arrow
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography sx={{ fontStyle: 'italic' }}>
              By checking this box, you confirm that you have read, understand
              and agree to the{' '}
              <Link href="#" style={{ cursor: 'pointer' }}>
                Terms of Service
              </Link>{' '}
            </Typography>
          </Tooltip>
        }
      />
    </Grid>
  );
};

export default TermsOfService;

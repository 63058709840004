import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { brand } from '../../../theme/theme';
import { useNavigate } from 'react-router-dom';

interface PersonalInformationProps {
  profileData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  profileData,
  handleChange,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        id="modal-title"
        variant="h6"
        sx={{ color: brand[500] }}
        component="h2"
        gutterBottom
      >
        Personal Information
      </Typography>
      <Typography
        id="modal-description"
        variant="subtitle1"
        color="textSecondary"
        sx={{ mb: 2, fontStyle: 'italic' }}
        gutterBottom
      >
        Only your first name and profile picture will be visible to other users
        by default.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={profileData.email}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            name="first_name"
            value={profileData.first_name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            name="last_name"
            value={profileData.last_name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phone_number"
            value={profileData.phone_number}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Student ID Number"
            name="student_id"
            value={profileData.student_id}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: 600 }}>
              Notifications preference:
            </Typography>
            <FormGroup row>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.email_notifications_on || false}
                    onChange={handleCheckboxChange}
                    name="email_notifications_on"
                    color="primary"
                  />
                }
                label="Email"
                sx={{ mr: 2 }}
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.text_notifications_on || false}
                    onChange={handleCheckboxChange}
                    name="text_notifications_on"
                    color="primary"
                  />
                }
                label="SMS Message"
              />
            </FormGroup>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '12px', fontStyle: 'italic', lineHeight: '20px' }}
          >
            You consent to receive SMS messages from Dublease. Standard Message
            and data rates may apply. Reply STOP to unsubscribe, and HELP for
            support.{' '}
            <Link
              onClick={() => navigate('/Terms&Conditions')}
              sx={{ cursor: 'pointer', color: brand[500] }}
            >
              Terms & Conditions
            </Link>
            ,{' '}
            <Link
              onClick={() => navigate('/PrivacyPolicy')}
              sx={{ cursor: 'pointer', color: brand[500] }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInformation;

import React from 'react';
import {
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { auth } from '../../../../config/firebase-config';
import { AttachMoney, WifiPassword } from '@mui/icons-material';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import AirIcon from '@mui/icons-material/Air';
import WeekendIcon from '@mui/icons-material/Weekend';
import KitchenIcon from '@mui/icons-material/Kitchen';
import PetsIcon from '@mui/icons-material/Pets';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { brand } from '../../../../theme/theme';

interface FormInputsProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInputs: React.FC<FormInputsProps> = ({
  formData,
  onChange,
  setFormData,
}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')); // Breakpoint for mobile view
  const amenitiesList = [
    { label: 'WiFi', icon: <WifiPassword /> },
    // { label: 'Utilities', icon: <WifiPassword /> },
    { label: 'Gym', icon: <FitnessCenterIcon /> },
    { label: 'Pool', icon: <PoolIcon /> },
    { label: 'Furnished', icon: <WeekendIcon /> },
    { label: isMobile ? 'AC' : 'Air Conditioning', icon: <AirIcon /> },
    { label: 'Laundry', icon: <LocalLaundryServiceIcon /> },
    { label: 'Dishwasher', icon: <KitchenIcon /> },
    { label: 'Pet Friendly', icon: <PetsIcon /> },
    { label: 'Parking', icon: <LocalParkingIcon /> },
  ];
  // Function to handle selected address from dropdown
  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log('Success', latLng); // Possibly integrate latLng into your form state if needed
      setFormData((prevState: any) => ({
        ...prevState,
        address,
      }));
    } catch (error) {
      console.error('Error', error);
    }
  };
  return (
    <Grid container spacing={1}>
      {/* Email */}
      <Grid item xs={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={auth.currentUser?.email}
          disabled={true}
        />
      </Grid>
      {/* Address with Autocomplete */}
      <Grid item xs={12}>
        <PlacesAutocomplete
          value={formData.address}
          onChange={(address: string) =>
            setFormData((prevState: any) => ({ ...prevState, address }))
          }
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <TextField
                {...getInputProps({
                  label: 'Address',
                  className: 'location-search-input',
                  fullWidth: true,
                  margin: 'normal',
                  required: true,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Grid>
      {/* Start Date */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="start_date"
          label="Start Date"
          name="start_date"
          type="date"
          value={formData.start_date}
          onChange={onChange}
          InputLabelProps={{
            shrink: true, // Always shrink the label
          }}
        />
      </Grid>
      {/* End Date */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="end_date"
          label="End Date"
          name="end_date"
          type="date"
          value={formData.end_date}
          onChange={onChange}
          InputLabelProps={{
            shrink: true, // Always shrink the label
          }}
        />
      </Grid>
      {/* Price */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="price"
          label="Price"
          name="price"
          type="number"
          inputMode="numeric"
          value={formData.price}
          onChange={onChange}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* Price Unit */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="price_unit"
          label="Price Unit"
          name="price_unit"
          value={formData.price_unit}
          onChange={onChange}
        >
          <MenuItem value="week">Per week</MenuItem>
          <MenuItem value="month">Per month</MenuItem>
        </TextField>
      </Grid>
      {/* Number of Rooms for Lease */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="num_rooms_for_lease"
          label="Number of Rooms for Lease"
          name="num_rooms_for_lease"
          type="number"
          inputMode="numeric"
          InputProps={{ inputProps: { min: 0 } }}
          value={formData.num_rooms_for_lease}
          onChange={onChange}
        />
      </Grid>
      {/* Total Bedrooms */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="num_property_bedrooms"
          label="Total Bedrooms In The House"
          name="num_property_bedrooms"
          type="number"
          inputMode="numeric"
          InputProps={{ inputProps: { min: 0 } }}
          value={formData.num_property_bedrooms}
          onChange={onChange}
        />
      </Grid>
      {/* Bathrooms */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="num_bathrooms"
          label="Number of Bathrooms"
          name="num_bathrooms"
          type="number"
          inputMode="numeric"
          InputProps={{ inputProps: { min: 0 } }}
          value={formData.num_bathrooms}
          onChange={onChange}
        />
      </Grid>
      {/* Roomates Are Aware */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="are_roommates_aware"
          label="Are Roommates Aware?"
          name="are_roommates_aware"
          select
          value={formData.are_roommates_aware}
          onChange={onChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
          <MenuItem value="N/A">N/A</MenuItem>
        </TextField>
      </Grid>
      {/* Roomates Will Be Present */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="num_roommates_present_during_dublease"
          label="Number of Roommates Present During Sublease"
          name="num_roommates_present_during_dublease"
          type="number"
          value={formData.num_roommates_present_during_dublease}
          onChange={onChange}
        />
      </Grid>
      {/* Gender Composition */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="housing_gender"
          label="Gender composition of the house?"
          name="housing_gender"
          select
          value={formData.housing_gender}
          onChange={onChange}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Mixed">Mixed</MenuItem>
        </TextField>
      </Grid>
      {/* Ammenities */}
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Select Amenities
        </Typography>
        <Grid container spacing={2}>
          {amenitiesList.map((amenity) => (
            <Grid item xs={4} sm={2} key={amenity.label}>
              <Box
                onClick={() => {
                  setFormData((prev: any) => {
                    const amenities = prev?.amenities || []; // Safely access amenities
                    const isSelected = amenities.includes(amenity.label);
                    return {
                      ...prev,
                      amenities: isSelected
                        ? amenities.filter(
                            (item: string) => item !== amenity.label
                          )
                        : [...amenities, amenity.label],
                    };
                  });
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 1,
                  borderColor: 'transparent',
                  borderRadius: 5,
                  padding: 1,
                  cursor: 'pointer',
                  backgroundColor: formData.amenities?.includes(amenity.label)
                    ? brand[300]
                    : brand[100],
                  color: formData.amenities?.includes(amenity.label)
                    ? 'white'
                    : 'black',
                  '&:hover': {
                    backgroundColor: formData.amenities?.includes(amenity.label)
                      ? brand[300]
                      : brand[300],
                  },
                }}
              >
                {amenity.icon}
                <Typography variant="caption">{amenity.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Description */}
      <Grid item xs={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="description"
          label="Description"
          name="description"
          placeholder="Provide any other relevant information about your property,
            lease policies, furnishing details, utilities coverage, or
            explanations for previous inputs."
          type="text"
          multiline
          rows={4}
          value={formData.description}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default FormInputs;

import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Lease } from '../../../types/Lease';

interface ImageUploadProps {
  setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setFormData: React.Dispatch<React.SetStateAction<Lease>>;
  formData: Lease;
}
const ImageUpload: React.FC<ImageUploadProps> = ({
  setImageFiles,
  setFormData,
  formData,
}) => {
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const urls = fileArray.map((file) => URL.createObjectURL(file));
      setFormData((prev) => ({
        ...prev,
        image_urls: [...(prev.image_urls || []), ...urls],
      }));
      setImageFiles((prev) => [...prev, ...fileArray]);
    }
  };

  const handleDeleteImage = (index: number, imageUrl: string) => {
    if (formData?.image_urls?.includes(imageUrl)) {
      // Add the deleted image URL to the list of deleted images
      setDeletedImages((prev) => [...prev, imageUrl]);
    }
    setFormData((prev) => ({
      ...prev,
      image_urls: prev?.image_urls?.filter((_, idx) => idx !== index),
    }));
    setImageFiles((prevFiles) => prevFiles.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    // Attach deletedImages to the formData for submission
    setFormData((prev) => ({
      ...prev,
      delete_image_urls: deletedImages,
    }));
  }, [deletedImages, setFormData]);

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Upload Images or Videos
      </Typography>
      <Button
        component="label"
        variant="outlined"
        color="primary"
        fullWidth
        sx={{ mt: 2, mb: 2 }}
      >
        Choose Files
        <input
          type="file"
          multiple
          hidden
          onChange={handleImageChange}
          accept="image/*,video/*"
        />
      </Button>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {formData?.image_urls?.map((url, index) => (
          <Box
            key={index}
            sx={{ position: 'relative', width: 100, height: 100, mb: 4 }}
          >
            <img
              src={url}
              alt={`Preview ${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <IconButton
              onClick={() => handleDeleteImage(index, url)}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'primary.main',
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'grey.300' },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default ImageUpload;

/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';
import { auth } from '../../config/firebase-config';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Grid, Pagination, Skeleton, Typography } from '@mui/material';
import { Lease } from '../types/Lease';
// import LeaseDetailsModal from './LeaseDetailsModal';
import LeaseCard from './LeaseCard';
import { getLeases } from '../../api/leaseService';
import { useAuthState } from 'react-firebase-hooks/auth';
import LeaseFilter from './Filters';
import { getOwnerLeases } from '../../api/interactionService';
import { Outlet, useNavigate } from 'react-router-dom';

function LeaseDisplay() {
  const [user] = useAuthState(auth);
  const [leases, setLeases] = useState<Lease[]>([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  // Filter States
  const [myListings, setMyListings] = useState<boolean>(false);
  const [seeBids, setSeeBids] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [location, setLocation] = useState<string | null>(null);
  const [numBeds, setNumBeds] = useState<number | ''>('');
  const [numRoommates, setNumRoommates] = useState<number | ''>('');
  const [sortPrice, setSortPrice] = useState<string>('asc');

  // Fetch Leases
  useEffect(() => {
    const loadLeases = async () => {
      const minimumLoadingTime = 1000; // Minimum loading time in ms (1 second)
      const startTime = Date.now();
      setLoading(true);
      try {
        //TODO refactor API call
        const filters = {
          startDate,
          endDate,
          location,
          numBeds,
          numRoommates,
          sortPrice,
          seeBids,
        };
        // Fetch owner leases
        const ownerLeases = await getOwnerLeases(user?.uid || '');

        let posts = [];
        let totalCount = 0;

        // Fetch other posts only if `myListings` is false
        if (!myListings) {
          const otherPosts = await getLeases(page, filters);
          posts = otherPosts.posts || [];
          totalCount = otherPosts.totalCount || 0;
        }

        setTotalPages(Math.ceil(totalCount / 12)); // Assume 12 items per page
        setLeases([...ownerLeases, ...posts]); // Combine owner leases and other posts
      } catch (error) {
        console.error('Error loading leases:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };

    // Fetch initial leases
    loadLeases();
  }, [
    page,
    user,
    startDate,
    endDate,
    location,
    numBeds,
    numRoommates,
    sortPrice,
    myListings,
    seeBids,
  ]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value); // Update page to load new set of leases
  };

  // Skeleton loading placeholders
  const renderSkeletons = () => (
    <Grid container spacing={2} sx={{ padding: '13px' }}>
      {[...Array(12)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.3} key={index}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={400}
            sx={{ borderRadius: '8px', width: { xs: '100%', sm: '95%' } }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        mt: 5,
      }}
    >
      <LeaseFilter
        myListings={myListings}
        setMyListings={setMyListings}
        seeBids={seeBids}
        setSeeBids={setSeeBids}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        location={location}
        setLocation={setLocation}
        numBeds={numBeds}
        setNumBeds={setNumBeds}
        numRoommates={numRoommates}
        setNumRoommates={setNumRoommates}
        sortPrice={sortPrice}
        setSortPrice={setSortPrice}
      />
      <Box sx={{ flexGrow: 1, padding: { xs: 0, sm: 2 } }}>
        {loading ? (
          renderSkeletons()
        ) : leases.length > 0 ? (
          <>
            <Grid container spacing={2} sx={{ padding: { xs: 0, sm: 1 } }}>
              <AnimatePresence>
                {leases.map((lease, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2.3} // Make 5 leases appear in a row on large screens
                    key={lease.id}
                    style={{ paddingBottom: '20px' }}
                  >
                    <motion.div
                      initial={{ opacity: 0, y: 30 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -30 }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: 'easeOut',
                      }}
                      style={{
                        width: '100%',
                        maxWidth: '345px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <LeaseCard
                        lease={lease}
                        onClick={() => navigate(`/posts/${lease.id}`)}
                      />
                    </motion.div>
                  </Grid>
                ))}
              </AnimatePresence>
            </Grid>
            {/* Pagination */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 2,
                paddingTop: 1,
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              padding: { xs: 2, sm: 20 },
            }}
          >
            <Typography variant="h4" color="textSecondary" gutterBottom>
              No leases available yet.
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Be the first to post a lease and help others find their next home!
            </Typography>
          </Box>
        )}
      </Box>
      {/* Render the nested route (LeaseDetailsModal) */}
      <Outlet />
    </Box>
  );
}

export default LeaseDisplay;

import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Fetch the user profile (used: profile.tsx)
export const getNotifications = async () => {
  try {
    const user = auth.currentUser;

    if(!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/notifications`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user profile: ${response.statusText}`);
    }

    const data = await response.json();
    return data.notifications || [];
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};